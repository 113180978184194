.page-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    border-radius: 0;
    border-right-width: 0;
    border-left-width: 0;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.config-container {
    padding-right: 0;
    padding-left: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.title {
    display: flex;
    flex-direction: column;
    flex: 0.5;
    justify-content: space-around;
    align-items: center;
    padding-top: 2rem;
}

.subtitle {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 0.5rem;
}

.links-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.side-panel {
    height: 100%;
    border-radius: 0;
    overflow: auto;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

p {
    margin-left: 2rem;
    margin-right: 2rem;
    max-width: 1000px;
}


