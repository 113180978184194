$color-primary: #005A9C;
$color-secondary: #6c757d;
$color-background: #fff;
$color-warning: #f8c839;
$color-success: #5fb173;
$color-danger: #c45b65;
$color-dark-background: #222;
$color-ocean-blue: #005A9C;
$logo-red: #e3423b;
$logo-dark-Blue: #0d3a48;
$logo-light-blue: #01a0b6;
$color-gray-400: #ced4da;
