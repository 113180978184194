@import "/src/theme";

.chore-chart-day {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 0;
    min-width: 8rem;
    min-height: 4rem;
    text-align: center;
    border: 1px solid $color-gray-400;
}

.chore-chart-action {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 3rem;
    margin-left: 5px;
    text-align: center;
}

.chore-chart-user {
    width: 100%;
    position: absolute;
}

.form-buttons {
    margin-left: auto;
    margin-right: 10px;
    border-radius: 10px;
    min-width: 80px;
}

.close-button {
    margin-right: 5px;
}
