.profile-picture {
    cursor: pointer;
}

.profile-picture:hover {
    cursor: pointer;
    filter: grayscale(60%);
}

.form-buttons {
    margin-left: auto;
    margin-right: 10px;
    border-radius: 10px;
    min-width: 80px;
}

.close-button {
    margin-right: 5px;
}
