.linked-card {
    &:hover {
        .card {
            background-color: rgba(0, 0, 0, .05);
        }
    }

    .card {
        min-height: 102px;
        border: none;
    }

    .card-body {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    text-decoration: none !important;
}

.linked-card-disabled {
    .card {
        min-height: 102px;
        cursor: default;
        border: none;
    }

    .card-body {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    text-decoration: none !important;
}
