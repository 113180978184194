.form-errors {
    font-size: 12px;
    color: red;
}

.error {
    border-color: red;
}

.check-box-input {
    label {
        margin-bottom: 0 !important;
    }
}
