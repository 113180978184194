.footer {
    display: flex;
    bottom:0;
    right:0;
    font-size: 12px;
    justify-content: flex-end;
    margin: 0.5rem;
}

.footer-text {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 1rem;
}

.logo {
    max-height: 5rem;
    width: 100%;
    margin: 0 1.5rem 0 0;
}
