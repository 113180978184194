.container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.image {
    width: 80%;
    height: auto;
    max-height: 8rem;
    max-width: 8rem;
}
