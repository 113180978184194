.responsive-table-wrapper {
    overflow-x: auto;
    white-space: pre-wrap;
    word-break: initial;
    max-width: 100%;
    min-width: 100%;
}

.table-auto {
    table-layout: auto;
    width: inherit !important;
    min-width: 100%;
}

.button-groups-container {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
}

.button-group {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-bottom: 10px;
    overflow-x: auto;

    Button {
        margin-right: 5px;
    }
}

.on-top {
    z-index: 1050;
}
