.page-body {
    border-radius: 0;
    border-right-width: 0;
    border-left-width: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-wrap: wrap;
}

.config-container {
    padding-right: 0;
    padding-left: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.lists-container {
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.list {
    flex-direction: row;
    min-width: 20rem;
    flex: 1;

    video {
        min-width: 10rem;
        position: relative;
        width: 100%;
    }
}

.list-item {
    display: flex;
    width: 100%;
    flex: 1;
    flex-direction: row;
}
