.centered-spinner {
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    pointer-events: none;
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
