.form-buttons {
    margin-left: auto;
    margin-right: 10px;
    border-radius: 10px;
    min-width: 80px;
}

.close-button {
    margin-right: 5px;
}

.form-tab {
    box-sizing: border-box;
    padding-top: 25px;
}

.tab {
    color: red !important;
}
